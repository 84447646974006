.Project{
    margin-top: 100px;
}

.projectDesc{
    margin-top: 0;
    width: 40%;
}

.flexBox{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 40px;
}

.flexBox img {
    width: 40%;
    max-width: 700px;
    height: auto;
}

.techStack{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-evenly;
    text-align: center;
}

.iconList, .techList{
    display: flex;
    justify-content: space-evenly;
    list-style: none;
}

.iconList > li > a{
    width: fit-content;
    color: aqua;
    transition: all .2s ease-in-out;
}

.iconList > li > a:visited {
    text-decoration: none;
    color: aqua;
}

.iconList > li > a:hover {
    text-decoration: none;
    color: greenyellow;
}

.techList > div{
    flex: 1;
}



@media (max-width: 800px){
    .flexBox{
        flex-direction: column;
    }
    .flexBox img{
        width: 80%;
        height: auto;
    }

    .projectDesc{
        margin-top: 10px;
        width: 80%;
    }

    .techList{
        flex-direction: column;
    }
}