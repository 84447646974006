* {
  scroll-behavior: smooth;
  margin: 0;
  overflow-x: hidden;
}

body{
  min-height: 100vh;
}

body::after{
  content: '';
  display: block;
  height: 50px;
}

hr{
  width: 80%;
  text-align: center;
}

p a{
  color:rgb(12, 28, 53);
  font-style: italic;
}

body {
  background: #0b0f20;
  color: white;
  position: relative;
}

.invis{
  visibility: hidden;
}

.nameNav{
  margin-left: 50px;
  z-index: 10001;
  color:aqua;
  font-size: 30px;
}

.mob-btn {
  z-index: 999;
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  cursor: pointer;
  transition: all .2s ease-in-out;
  border: 3px solid #fff
}

.mob-btn__burger{
  width: 24px;
  height: 3px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
  transition: all .2s ease-in-out;
}

.mob-btn__burger::before,
.mob-btn__burger::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 3px;
  background: #fff;
  box-shadow: 0 2px 5px rgba(255, 101, 47, .2);
  transition: all .2s ease-in-out;
}

.mob-btn__burger::before{
  transform: translateY(-8px);
}

.mob-btn__burger::after{
  transform: translateY(8px);
}

.mob-btn.open .mob-btn__burger {
  /* transform: translateX(-25px); */
  background: transparent;
  box-shadow: none;
}

.mob-btn.open .mob-btn__burger::before {
  transform: rotate(45deg);
}

.mob-btn.open .mob-btn__burger::after {
  transform: rotate(-45deg);
}

nav{
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: #2c324b;
  color: blue;
  z-index: 1;
}

.name{
  text-align: center;
}

.left, .right{
  display: flex;
}

.left, .name, .right{
  flex: 1;
}

.left a{
  text-decoration: none;
}

.right{
  justify-content: flex-end;
  align-items: center;
  color: aqua;
  margin-right: 50px;
}

.right h2{
  font-size: 20px;
}

.right i{
  display: none;
}

.nav-links{
  z-index: 1000;
  justify-content: flex-end;
  margin-right: 10px;
  transition: all .2s ease-in-out;
  word-wrap: normal;
  
}

.nav-links a{
  display:inline-block;
  list-style-type:none;
  margin-right:-4px;
  padding:10px;
  cursor: pointer;
  text-decoration: none;
  transition: all .2s ease-in-out;
  color: white;
}

.nav-links a:hover{
  color: rgb(42, 42, 255);
}

.right i{
  display: none;
  margin: 10px
}


.mob-false, .mob-true{
  display: none;
}


.Page{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  max-width:1290px;
  margin: 100px auto;
}

/* .About{
  margin-left: 7%;
  margin-right:7%;
} */

.pageTitle{
  width: fit-content;
  word-wrap: normal;
  text-align: left;
  color:aqua;
  margin-right: auto;
  margin-bottom: 50px;
}

.pageTitle h1{
  font-size: 96px;
}

.Intro{
  margin-top: 100px;
  flex-direction: row;
  justify-content: space-between;
}

.introText{
  word-wrap: normal;
}

.nameTitle{
  color:aqua;
  margin-bottom: 20px;
  font-size: 120px;
}

.introBox{
  text-align: center;
}

.imgBox{
  max-width: 40%;
  height: auto;
}

.question{
  display: flex;
  justify-content: start;
  width: 95%;
  font-style: italic;
  margin: 70px 0px auto 20px;  
}

.answer{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 95%;
  margin: auto 20px 70px auto;
}

.encryptWord{
  text-decoration: underline;
  color: aqua;
  cursor: pointer;
}


.pageText{
  display: flex;
  flex-direction: row;
  line-height: 35px;
  justify-content: center;
  align-items: center;

}

.pageTextCenter{
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
  margin-right: 20px;
}

.pageTextCenter > p{
  margin-bottom: 10px;
}

.bar {
  width: 100%; /* Full width */
  background-color: rgb(83, 83, 83); /* Grey background */
}

.skillBarContainer{
  width: 100%;
  margin-bottom: 50px;
}

.skillBar{
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right; /* Right-align text */
  padding-top: 10px; /* Add top padding */
  padding-bottom: 10px; /* Add bottom padding */
  color: white; /* White text color */
}

.skillBar span{
  margin-left: 20px;
}

.skillBar h1{ margin-right: 20px; color: black;}

.frontBar{ width: 80%; background-color: red; }
.backBar{ width: 90%; background-color: rgb(180, 180, 0); }
.reactBar{ width: 85%; background-color: rgb(0, 162, 255); }
.javascriptBar{ width: 90%; background-color: rgb(67, 185, 83); }

.skillText{
  margin-left: 50px;
}

.Projects{
  max-width: 100vw !important;
}

.Projects .pageTitle {
  max-width: 1290px !important;
  margin: 0 0 50px 0;
  width: 100vw;
}

.project{
  width: 100vw;
  text-align: center;
}

.projectCatalog{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.projectHeader{
  display: flex;
  justify-content: space-between;
  margin: 20px 20px;
}

.projectHeader h1{
  font-size: 72px;
}

.projectHeader h2{
  font-size: 48px;
}

spacer{
  border: 1px solid white;
  margin: 0 auto;
  width: 98vw;
  display: block;
}

.projectImage{
  position: relative;
  color: white;
  min-height: 60vh;
}

.projectImage a {
  min-height: 60vh;
  display: block;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-decoration: none;
  color: white;
}

/* .projectImage h1{
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform .3s;
  pointer-events: none;
}

.projectImage:hover h1{
  transform: translate(-50%, -130%)
}

.projectImage h2{
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform .3s;
  pointer-events: none;
}

.projectImage:hover > h2{
  transform: translate(-50%, 80%);
}

.projectImage:hover{
  color: white;
} */

.project {
  width: 100vw;
}

.project img{
  width: 100%;
  height: 300px;
  border: 1px solid white;
  cursor: pointer;
  transition: all .2s ease-out;
}



.Skills{
  flex-direction: column;
  
}


.skillList{
  list-style: none;
  width: 100%;
}

.skillList li{
  cursor: pointer;
  transition: all .2s ease-out;
  display: inline-block;
  background: rgb(29, 68, 126);
  margin: 5px;
  border-radius: 10%;
  padding: 2px;
}

.skillList li:hover{
  font-size: 120%;
}

.Contact{
  margin-top: 50px;
  margin-bottom: 50px;
}

.Contact .pageTitle{
  margin-left: auto;

}

.headshot, .mouse {
  position: absolute;
  top: 0;
  margin: 0 auto;
  border: solid 20px rgb(27, 27, 27);
  background-image: url("images/me.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.headshot{
  filter: brightness(8%);
}

.mouse {
  clip-path: circle(0em at 0 0);
}

.contactList{
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0;
  align-items: center;
}

.contactList > li{
  flex: 1 1 100%;
  min-width: 0;
  width: fit-content;
  white-space: nowrap;
  text-align: center;
  margin: 0 auto;
}

.phoneContact{
  cursor: pointer;
  color: aqua;
  transition: all .2s ease-in-out;
}

.phoneContact:hover{
  color: greenyellow;
}

.contactList > li > a{
  width: fit-content;
  color: aqua;
  transition: all .2s ease-in-out;

}

.contactList > li > a:visited {
  text-decoration: none;
  color: aqua;
}

.contactList > li > a:hover {
  text-decoration: none;
  color: greenyellow;
}

.contactButton{
  padding: 5px 11px;
  background-color: aqua;
  color: #2c324b;
  transition: all .2s ease-in-out;
  font-size: 24px;
  margin-left: 7px;
  cursor: pointer;
  border-radius: 10px;
}

.contactButton:hover{
  background-color: #2c324b;
  padding: 6px 13px;
}

.contactButton a {
  text-decoration: none;
}

.contactButton a:hover{
  color: aqua;
}

.contactButton a:active{
  color: aqua !important;
}

footer{
  margin-top: 10px;
  width: 100vw;
  height: 50px;
  background: #2c324b;
  text-align: center;
  color: aqua;
  position: absolute;
  bottom: 0;
}

footer h1 svg{
  color: red;
}

@media (max-width:1200px){

  .nameNav{
    margin-left: 0px;
  }
  .nameTitle{
    color:aqua;
    font-size: 96px;
    margin-bottom: 20px;
  }

  .right h2{
    display: none;
  }

  .right{
    margin-right: 0px;
  }



  .projectHeader{
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .projectHeader h3{
    text-align: center !important;
  }

  .projectHeader h1{
    font-size: 60px;
  }

  .mob-btn{
    display: flex;
    margin-left: 20px;
    height: 60px;
    width: 60px;
  }

  nav{
    width: 100%;
    margin-top: 0px;
  }

  .nav-links-mobile{
    position: fixed;
    top: 60px;
    width: 100%;
    text-align: center;
    flex-direction: column;
    transition: all .2s ease-in-out;
    z-index: 1000;

  }

  .nav-links{
    display: none;
  }

  .nav-links-mobile a {
    height: 100px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    color: black;
    background-color: white;
    transition: all .2s ease-in-out;
    line-height: 100px;
    font-size: 30px;
    border: 1px solid aqua;
  }
  
  .nav-links-mobile a:hover {
    color:rgb(42, 42, 255);
  }
  
  .nav-links-mobile a h1{
    vertical-align: middle;
  }

  .nav-links-mobile.mob-false{
    transform: translateY(50px);
    height: 0;
    opacity: 0;
  }

  .right i{
    display: block;
  }

  .mob-true, .mob-false{
    display: flex;
  }

  .false, .true{
    display: none;
  }

  .pageTitle{
    margin-left: auto;
    text-align: center;
  }

  .pageTitle h1{
    font-size: 72px;
  }

  .pageText{
    flex-direction: column;
  }

  .pageTextCenter{
    max-width: 100%;
    text-align: center;
  }

  .decryptLine{
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .Intro{
    flex-direction: column;
  }

  .introText{
    width: 80%;
    text-align: center;
  }

  .project img{
    max-width: 100%;
  }
}

@media (max-width: 700px) {
  .contactButton{
    display: none;
  }
  .nameNav{
    font-size: 24px;
  }
  .pageTitle h1{
    font-size: 60px;
  }

  .nameTitle{
    font-size: 72px;
  }
  .mob-btn {
    margin-left: 5px;
  }
}